export const COMMON_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  DELETED: 3,
  NOT_VERIFIED: 4,
};

export const PAYMENT_STATUS = {
  PENDING: 0,
  PAID: 1,
  UNPAID: 2,
  CANCELLED: 3,
};

export const PAID_PACKAGES_TYPE = {
  SUBSCRIBE: 0,
  PREMIUM_POST: 1,
};

export const REQUEST_TYPE = {
  RENT: 1,
  SELL: 2,
};

export const REQUEST_STATUS = {
  APPROVED: 1,
  REJECTED: 2,
  VISIT: 3,
  ACTIVATED: 4,
  RENTED: 5,
  SOLD: 6,
  DELETED: 7,
  PENDING: 8,
  EXPIRED: 9,
};

export const REQUEST_STATUS_LOOKUP = [
  { title: { en: 'Approved', ar: 'تم الموافقة' }, id: 1 },
  { title: { en: 'Rejected', ar: 'تم الرفض' }, id: 2 },
  { title: { en: 'ًWaiting For Visit', ar: 'بإنتظار الزيارة' }, id: 3 },
  { title: { en: 'Activated', ar: 'مفعل' }, id: 4 },
  { title: { en: 'Rented', ar: 'مستأجرة' }, id: 5 },
  { title: { en: 'Sold', ar: 'مباعة' }, id: 6 },
  { title: { en: 'Deleted', ar: 'محذوفة' }, id: 7 },
  { title: { en: 'Pending', ar: 'قيد الإنتظار' }, id: 8 },
  { title: { en: 'Expired', ar: 'منتهي الصلاحية' }, id: 9 },
];

export let countryData = [
  {
    en: 'Kuwait', //country name in english
    ar: 'Kuwait', //country name in arabic
    iso2: 'KW', //unique official ISO2 code of country
    // flag: require("@images/ic_kuwait.png") //country flag name saved in images folder
  },
];

export let userTypeData = [
  { en: 'Personal', ar: 'Personal', value: 1 },
  { en: 'Company', ar: 'Company', value: 2 },
];

export const userTypesEn = [
  { label: 'Personal', value: 2 },
  { label: 'Company', value: 3 },
];
export const userTypesAr = [
  { label: 'شخصي', value: 2 },
  { label: 'شركة', value: 3 },
];

export const iso2options = [
  {
    value: 1,
    label: 'Kuwait', //country name in english
    iso2: 'KW', //unique official ISO2 code of country
  },
];

export const booleansAr = [
  { label: 'نعم', value: true },
  { label: 'لا', value: false },
];
export const booleansEn = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const requestPurposeAr = [
  { label: 'ايجار', value: 1 },
  { label: 'بيع', value: 2 },
];

export const requestPurposeEn = [
  { label: 'Rent', value: 1 },
  { label: 'Sell', value: 2 },
];
