/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { useNavigate } from 'react-router-dom';
import './compStyle.css'; // Import custom CSS for hover effects

const TotalActivated = (props) => {
  const { translate } = useLanguage();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/activeProperties');
  };
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card className="hoverable-card" onClick={handleClick}>
        <CardBody className="dashboard__card-widget">
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--lime">
              <p className="mobile-app-widget__total-stat">{props.count}</p>
            </div>
            <div className="mobile-app-widget__title">
              <h4>{translate('totalActivated')}</h4>
            </div>
            <div className="progress-wrap progress-wrap--small progress-wrap--lime-gradient progress-wrap--label-top"></div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

TotalActivated.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('common')(TotalActivated);
