import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';

import { NotificationAPI, UserAPI } from '../../../../apis';
import { showToast } from '../../../../utils/toast';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { sessionExpire } from '../../../../redux/actions/authAction';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

const HorizontalForm = ({ handleSubmit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLanguage();

  const [formData, setFormData] = useState({
    title: {
      en: '',
      ar: '',
    },
    body: {
      en: '',
      ar: '',
    },
  });

  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [sendToAllUsers, setSendToAllUsers] = useState(false);
  const [sendToAllCompanies, setSendToAllCompanies] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const addNotificationHandeller = async () => {
    try {
      let response;
      if (sendToAllUsers || sendToAllCompanies) {
        const req = { ...formData, sendToAllUsers, sendToAllCompanies };
        response = await NotificationAPI.sendBatchNotification(req);
      } else {
        if (selectedCompany) {
          response = await NotificationAPI.sendNotification({
            ...formData,
            userId: selectedCompany.value,
          });
        }
        if (selectedUser) {
          response = await NotificationAPI.sendNotification({
            ...formData,
            userId: selectedUser.value,
          });
        }
      }
      if (response.status === 200) {
        showToast(translate('notificationSentSuccessfully'), 'success');
        // navigate('/notification/list');
      } else {
        return showToast(response.message || translate('error'), 'error');
      }
    } catch (error) {
      if (!error.response) {
        return showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
        dispatch(sessionExpire());
      } else {
        return showToast(error.response.data.message || String(error), 'error');
      }
    }
  };

  const listUsers = async () => {
    try {
      const response = await UserAPI.listUsers();
      if (response.status === 200) {
        setUsers(response.data.data.listing);
      } else {
        return showToast(response.data.message || translate('error'), 'error');
      }
    } catch (error) {
      if (!error.response) {
        return showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
        dispatch(sessionExpire());
      } else {
        return showToast(error.response.data.message || String(error), 'error');
      }
    }
  };

  const listCompanyUsers = async () => {
    try {
      const response = await UserAPI.listCompanyUsers();
      if (response.status === 200) {
        setCompanies(response.data.data.listing);
      } else {
        return showToast(response.data.message || translate('error'), 'error');
      }
    } catch (error) {
      if (!error.response) {
        return showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
        dispatch(sessionExpire());
      } else {
        return showToast(error.response.data.message || String(error), 'error');
      }
    }
  };

  useEffect(() => {
    listUsers();
    listCompanyUsers();
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationTitleEN')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationTitleEN"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationTitle')}
                  value={formData.title.en}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { ...formData.title, en: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationTitleAR')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationTitleAR"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationTitle')}
                  value={formData.title.ar}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      title: { ...formData.title, ar: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationBodyEN')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationBodyEN"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationBody')}
                  value={formData.body.en}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      body: { ...formData.body, en: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('notificationBodyAR')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="notificationBodyAR"
                  component="input"
                  type="text"
                  placeholder={translate('enterNotificationBody')}
                  value={formData.body.ar}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      body: { ...formData.body, ar: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                // marginTop: 10,
                marginBottom: 30,
                gap: 30,
              }}
            >
              <div>
                <label className="form__form-group-label">
                  <input
                    type="checkbox"
                    checked={sendToAllUsers}
                    onChange={() => setSendToAllUsers(!sendToAllUsers)}
                  />
                  {translate('sendToAllUsers')}
                </label>
              </div>

              <div>
                <label className="form__form-group-label">
                  <input
                    type="checkbox"
                    checked={sendToAllCompanies}
                    onChange={() => setSendToAllCompanies(!sendToAllCompanies)}
                  />
                  {translate('sendToAllCompanies')}
                </label>
              </div>
            </div>

            {!sendToAllCompanies && !sendToAllUsers && (
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('sendToOneUser')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="user"
                    className="react-select"
                    placeholder={translate('selectUser')}
                    options={users.map((user) => ({
                      value: user._id,
                      label: user.nameAr,
                    }))}
                    value={selectedUser}
                    onChange={(e) => {
                      setSelectedUser({ value: e.value, label: e.label });
                    }}
                  />
                </div>
              </div>
            )}

            {!sendToAllCompanies && !sendToAllUsers && (
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('sendToOneCompany')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="company"
                    className="react-select"
                    placeholder={translate('selectCompany')}
                    options={companies.map((user) => ({
                      value: user._id,
                      label: user.nameAr,
                    }))}
                    value={selectedCompany}
                    onChange={(e) => {
                      setSelectedCompany({ value: e.value, label: e.label });
                    }}
                  />
                </div>
              </div>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.title.en ||
                  !formData.title.ar ||
                  !formData.body.en ||
                  !formData.body.ar
                }
                onClick={addNotificationHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(HorizontalForm);
