import React, { useState } from 'react';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { Button, Card, CardBody, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { REQUEST_STATUS } from '../../../../utils/allDropdownData';
import * as Config from '../../../../config';
import { sessionExpire } from '../../../../redux/actions/authAction';

import axios from 'axios';
import { showToast } from '../../../../utils/toast';
import useErrorHandling from '../../../../utils/error';
import SweetAlert from 'react-bootstrap-sweetalert';

const RequestItem = ({ request, onSellRent, onDelete }) => {
  const dispatch = useDispatch();
  const { translate, language } = useLanguage();
  const handleError = useErrorHandling();

  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    postExpiry: new Date().toISOString(),
  });

  const handleUpdate = async () => {
    const dataToSend = {
      _id: request?._id,
      // isPaidPost: true,
      // packageId: null,
      postExpiry: formData.postExpiry,
    };

    axios
      .post(
        `${Config.BASE_URL}Admin-Estate/reactivateEstateRequest`,
        dataToSend
      )
      .then((response) => {
        if (response.status === 200) {
          onSellRent();
          showToast(translate('updatedSuccessfully'), 'success');
          setShowPopup(false);
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const locationValue = request?.locationByUser || '-';

  const LinkifyText = ({ text }) => {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(linkRegex);

    return (
      <span>
        {parts.map((part, index) => {
          if (linkRegex.test(part)) {
            return (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                {part}
              </a>
            );
          }
          return <span key={index}>{part}</span>;
        })}
      </span>
    );
  };

  return (
    <>
      <div className="property-requests-table">
        <Link to={`/property/3/${request?.requestStatusId}/${request._id}`}>
          <div className="d-flex">
            <img
              src={
                request?.mainImage
                  ? `${Config.UPLOADS_URL}uploads/${request?.mainImage}`
                  : require('../../../../assets/images/icons/avatar.jpg')
              }
              alt="profile"
              style={{
                borderRadius: '10%',
                margin: '10px',
                width: '70px',
                height: '70px',
                marginLeft: '30px',
              }}
            />
            <Table responsive style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr style={styles.center}>
                  <p style={{ fontWeight: 'bold', margin: 0 }}>
                    {language === 'en'
                      ? request?.userDetails?.nameEn
                      : request?.userDetails?.nameAr}
                  </p>
                  <th style={styles.center}>{translate('governorate')}</th>
                  <th style={styles.center}>{translate('area')}</th>
                  <th style={{ width: '25%', minWidth: '200px' }}>
                    {translate('address')}
                  </th>
                  <th style={styles.center}>{translate('propertyType')}</th>
                  <th style={styles.center}>{translate('referenceNo')}</th>
                  <th style={styles.center}>{translate('agent')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={styles.center}>
                    <p>{request?.userDetails?.phoneNumber}</p>
                  </td>
                  <td style={styles.center}>
                    {request?.governorateDetails?.title?.[language]}
                  </td>
                  <td style={styles.center}>
                    {request?.areaDetails?.title?.[language]}
                  </td>
                  <td
                    style={{
                      width: '25%',
                      minWidth: '200px',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word',
                      textAlign: 'center',
                    }}
                  >
                    <LinkifyText text={locationValue} />
                  </td>
                  <td style={styles.center}>
                    {request?.propertyTypeDetails?.title?.[language]}
                  </td>
                  <td style={styles.center}>{request?.referenceNo}</td>
                  <td style={styles.center}>
                    {request?.agentDetails
                      ? language === 'en'
                        ? request?.agentDetails?.nameEn
                        : request?.agentDetails?.nameAr
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Link>
        <div className="actions">
          {/* <Button
            onClick={() => onDelete(request?._id)}
            color="danger"
            style={{ marginRight: '10px', padding: '5px 10px' }}
          >
            {translate('delete')}
          </Button> */}
          <Button
            onClick={() => setShowPopup(true)}
            color="success"
            style={{
              marginRight: '10px',
              padding: '5px 10px',
              width: '70px',
            }}
          >
            {translate('activate')}
          </Button>
          {/* <Button
            onClick={() => {}}
            color='success'
            style={{ padding: '5px 10px', background: 'green' }}
          >
            {translate('edit')}
          </Button>
          {request?.requestTypeId === REQUEST_TYPE.RENT && (
            <Button
              onClick={() => handleRent()}
              color='success'
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                width: '70px',
              }}
            >
              {translate('rented')}
            </Button>
          )}
          {request?.requestTypeId === REQUEST_TYPE.SELL && (
            <Button
              onClick={handleSell}
              color='success'
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                width: '70px',
              }}
            >
              {translate('soldOut')}
            </Button>
          )}*/}
        </div>
      </div>
      <div
        className="line"
        style={{
          width: '97%',
          border: '1px solid rgba(96, 96, 96, 0.1)',
          margin: '10px auto',
        }}
      />
      {showPopup && (
        <SweetAlert
          show={showPopup}
          title={translate('activate')}
          onConfirm={handleUpdate}
          onCancel={() => setShowPopup(false)}
          showCancel
          confirmBtnText={translate('submit')}
          cancelBtnText={translate('cancel')}
        >
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <form className="form form--horizontal">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('enterExpiry')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        name={'date'}
                        component="input"
                        type="date"
                        value={formData.postExpiry?.split('T')[0]}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            postExpiry: `${e.target.value}T00:00:00.000Z`,
                          })
                        }
                      />
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </SweetAlert>
      )}
    </>
  );
};
const styles = {
  center: {
    textAlign: 'center',
  },
};
export default RequestItem;
