import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import axios from 'axios';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { showToast } from '../../../../utils/toast';
import { sessionExpire } from '../../../../redux/actions/authAction';
import { useDispatch } from 'react-redux';
import ResponsiveInput from '../../../../shared/components/ResponsiveInput';
import * as Config from '../../../../config/index';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';
import Panel from '../../../../shared/components/Panel';
import { showDate } from '../../../../utils/functions';
import SweetAlert from 'react-bootstrap-sweetalert';
import useErrorHandling from '../../../../utils/error';

const HorizontalForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { translate, isRTL } = useLanguage();
  const [formData, setFormData] = useState({
    numberOfFreePosts: 0,
    turnOnPayment: false,
    freePostValidity: 0,
  });
  const [packages, setPackages] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formAdd, setFormAdd] = useState({
    titleAr: '',
    titleEn: '',
    validity: 0,
    price: 0,
  });
  const handleError = useErrorHandling();

  useEffect(() => {
    getItemHandler();
    getPackages();
  }, []);

  const getItemHandler = async () => {
    axios
      .get(`${Config.BASE_URL}Common/settings`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            numberOfFreePosts: response.data.data?.numberOfFreePosts,
            turnOnPayment: response.data.data?.turnOnPayment,
            freePostValidity: response.data.data?.freePostValidity,
          });
        } else {
          showToast(response.data.message || translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          showToast(error.response.data.message || String(error), 'error');
        }
      });
  };

  const getPackages = async () => {
    axios
      .get(`${Config.BASE_URL}Common/packages`)
      .then((response) => {
        if (response.status === 200) {
          setPackages(response.data.data);
        } else {
          showToast(response.data.message || translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          showToast(error.response.data.message || String(error), 'error');
        }
      });
  };

  const _onEditItemHandler = async () => {
    axios
      .put(`${Config.BASE_URL}Common/updateSettings`, formData)
      .then(async (response) => {
        if (response.status === 200) {
          getItemHandler();
          showToast(translate('updatedSuccessfully'), 'success');
        } else {
          showToast(response.data.message || translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          showToast(error.response.data.message || String(error), 'error');
        }
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add validation if needed
    _onEditItemHandler();
  };

  const onConfirmStatus = async (val) => {
    if (val) {
      setLoading(true);
      const url = `${Config.BASE_URL}Common/activeInnactivePackage/${selectedPackageId}`;
      axios
        .patch(url)
        .then(async (response) => {
          getPackages();
          setSelectedPackageId(null);
          setLoading(false);
          setAlertStatus(false);
        })
        .catch((error) => {
          setSelectedPackageId(null);
          setLoading(false);
          setAlertStatus(false);
          if (!error.response) {
            return showToast(String(error), 'error');
          } else if (error.response.status === 401) {
            showToast(translate('sessionExpire'), 'error');
            dispatch(sessionExpire());
          } else {
            return error.response.data.message
              ? showToast(error.response.data.message, 'error')
              : showToast(String(error), 'error');
          }
        });
    } else {
      setSelectedPackageId(null);
      setLoading(false);
      setAlertStatus(false);
    }
  };

  const onActiveInnactive = async (id) => {
    setSelectedPackageId(id);
    setAlertStatus(true);
  };

  const handleAdd = async () => {
    const dataToSend = {
      title: {
        en: formAdd.titleEn,
        ar: formAdd.titleAr,
      },
      validity: formAdd.validity,
      price: formAdd.price,
    };

    axios
      .post(`${Config.BASE_URL}Common/addPackage`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          getPackages();
          showToast(translate('updatedSuccessfully'), 'success');
          setShowPopup(false);
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form onSubmit={handleFormSubmit}>
              <ResponsiveInput
                label={translate('turnOnPayment')}
                id="turnOnPayment"
                type="checkbox"
                value={formData?.turnOnPayment || false}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    turnOnPayment: e.target.checked,
                  })
                }
              />

              <ResponsiveInput
                label={translate('numberOfFreePosts')}
                id="numberOfFreePosts"
                type="number"
                value={formData?.numberOfFreePosts || ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    numberOfFreePosts: e.target.value,
                  })
                }
                min="0"
              />

              <ResponsiveInput
                label={translate('freePostValidity')}
                id="freePostValidity"
                type="number"
                value={formData?.freePostValidity || ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    freePostValidity: e.target.value,
                  })
                }
                min="0"
              />

              <Button color="primary" type="submit" style={{ marginTop: 100 }}>
                {translate('submit')}
              </Button>
            </form>

            <div className="card">
              <div className="content">
                <Panel
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  title=""
                  subhead=""
                  refreshFunc={() => getPackages()}
                  addFunc={() => {
                    setShowPopup(true);
                  }}
                  placement={isRTL ? 'rtl' : 'ltr'}
                >
                  <div dir={isRTL ? 'rtl' : 'ltr'}>
                    <ResponsiveContainer className="dashboard__area">
                      <table className="table table-striped ">
                        <thead>
                          <tr>
                            <th>{translate('arTitle')}</th>
                            <th>{translate('enTitle')}</th>
                            <th>{translate('validity')}</th>
                            <th>{translate('price')}</th>
                            <th>
                              <div className="table-search cursor-pointer">
                                {translate('createdAt')}
                              </div>
                            </th>
                            <th>{translate('action')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {packages?.length > 0 &&
                            packages.map((val, i) => {
                              return (
                                <tr key={i}>
                                  <td>{val?.title?.ar}</td>
                                  <td>{val?.title?.en}</td>
                                  <td>{val?.validity}</td>
                                  <td>{val?.price}</td>
                                  <td>{showDate(val.createdAt)}</td>
                                  <td>
                                    <IconButton
                                      onClick={() => onActiveInnactive(val._id)}
                                      className={
                                        val.status === 1
                                          ? 'text-info'
                                          : 'text-danger'
                                      }
                                      aria-label="Edit"
                                    >
                                      <span
                                        title={
                                          val.status === 2 ? 'Unblock' : 'Block'
                                        }
                                        className="material-icons mr-10"
                                      >
                                        {val.status === 2
                                          ? 'lock'
                                          : 'lock_open'}
                                      </span>
                                    </IconButton>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </ResponsiveContainer>
                    <SweetAlert
                      info
                      showCancel
                      custom
                      confirmBtnText={translate('yes')}
                      show={alertStatus}
                      cancelBtnText={translate('no')}
                      confirmBtnBsStyle="primary"
                      cancelBtnBsStyle="default"
                      btnSize="sm"
                      title={translate('changeStatusAlert')}
                      onConfirm={() => onConfirmStatus(true)}
                      onCancel={() => onConfirmStatus(false)}
                    ></SweetAlert>
                  </div>
                </Panel>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      {showPopup && (
        <SweetAlert
          show={showPopup}
          title={translate('newPackage')}
          onConfirm={handleAdd}
          onCancel={() => setShowPopup(false)}
          showCancel
          confirmBtnText={translate('submit')}
          cancelBtnText={translate('cancel')}
        >
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <form className="form form--horizontal">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('enTitle')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={formAdd?.titleEn}
                        name="titleEn"
                        component="input"
                        type="text"
                        onChange={(e) =>
                          setFormAdd({
                            ...formAdd,
                            titleEn: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('arTitle')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={formAdd?.titleAr}
                        name="titleAr"
                        component="input"
                        type="text"
                        onChange={(e) =>
                          setFormAdd({
                            ...formAdd,
                            titleAr: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('validity')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={formAdd?.validity}
                        name="validity"
                        component="input"
                        type="text"
                        onChange={(e) =>
                          setFormAdd({
                            ...formAdd,
                            validity: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('price')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={formAdd?.price}
                        name="price"
                        component="input"
                        type="text"
                        onChange={(e) =>
                          setFormAdd({
                            ...formAdd,
                            price: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </SweetAlert>
      )}
    </>
  );
};

export default HorizontalForm;
