import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { useLanguage } from '../../i18n/LanguageContext';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authAction';
import './Sidebar.css';
import {
  FaChevronRight,
  FaChartBar,
  FaFileAlt,
  FaHome,
  FaUser,
  FaUsers,
  FaBell,
  FaPhone,
  FaCog,
  FaSignOutAlt,
  FaMapMarkerAlt,
  FaTrash,
  FaPencilAlt,
  FaHeart,
  FaFileSignature,
  FaFileInvoice,
} from 'react-icons/fa';

const Sidebar = ({ isSidebarOpen, closeSidebar }) => {
  const { isRTL, translate } = useLanguage();
  const dispatch = useDispatch();

  const [openCategories, setOpenCategories] = useState({});

  const toggleCategory = (category) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <>
      {/* Move the sidebar-overlay outside of the sidebar */}
      {isSidebarOpen && (
        <div className="sidebar-overlay" onClick={closeSidebar}></div>
      )}
      <div
        className={`sidebar ${isRTL ? 'rtl' : 'ltr'} ${
          isSidebarOpen ? 'open' : 'closed'
        }`}
      >
        <div className="sidebar-content">
          <div>
            {/* Dashboard */}
            <NavItem>
              <NavLink
                to="/dashboard"
                className="nav-link"
                onClick={closeSidebar}
              >
                <FaChartBar className="sidebar-icon" />
                <span className="sidebar-text">{translate('dashboard')}</span>
              </NavLink>
            </NavItem>

            {/* Requests Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('requests')}
              >
                <div className="sidebar-category-title">
                  <FaFileAlt className="sidebar-icon" />
                  <span className="sidebar-text">{translate('requests')}</span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['requests'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['requests']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/requestsAddProperty"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaFileSignature className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('requestsToAddProperty')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/visitingRequests"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaHeart className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('requestsToVisitProperty')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/rejectedRequests"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaTrash className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('rejectedRequests')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Properties Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('properties')}
              >
                <div className="sidebar-category-title">
                  <FaHome className="sidebar-icon" />
                  <span className="sidebar-text">
                    {translate('properties')}
                  </span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['properties'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['properties']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/activeProperties"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('propertiesList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/soldProperties"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('soldProperties')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/rentedProperties"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('rentedProperties')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/expiredProperties"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('expiredProperties')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/property/1/4/0"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaPencilAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('addProperty')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('invoices')}
              >
                <div className="sidebar-category-title">
                  <FaFileInvoice className="sidebar-icon" />
                  <span className="sidebar-text">{translate('invoices')}</span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['invoices'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['invoices']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/invoice/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaFileInvoice className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('invoiceList')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Estate Market Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('estateMarket')}
              >
                <div className="sidebar-category-title">
                  <FaFileAlt className="sidebar-icon" />
                  <span className="sidebar-text">
                    {translate('estateMarket')}
                  </span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['estateMarket'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['estateMarket']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/estateMarket/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('estateMarketList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/estateMarket/add"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaPencilAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('addEstateMarket')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Estate Requests Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('estateRequests')}
              >
                <div className="sidebar-category-title">
                  <FaFileAlt className="sidebar-icon" />
                  <span className="sidebar-text">
                    {translate('estateRequests')}
                  </span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['estateRequests'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['estateRequests']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/EstateRequestFind/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('estateRequestsList')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Admins Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('admins')}
              >
                <div className="sidebar-category-title">
                  <FaUsers className="sidebar-icon" />
                  <span className="sidebar-text">{translate('admins')}</span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['admins'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['admins']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/admins/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaUsers className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('adminsList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/admins/add"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaUser className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('addAdmin')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Agents Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('agents')}
              >
                <div className="sidebar-category-title">
                  <FaUsers className="sidebar-icon" />
                  <span className="sidebar-text">{translate('agents')}</span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['agents'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['agents']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/agents/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaUsers className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('agentsList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/agents/add"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaUser className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('addAgent')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Users Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('users')}
              >
                <div className="sidebar-category-title">
                  <FaUsers className="sidebar-icon" />
                  <span className="sidebar-text">{translate('users')}</span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['users'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['users']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/users/companyList"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaUsers className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('userCompanyList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/users/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaUsers className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('usersList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/users/add"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaUser className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('addUser')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Notifications Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('notifications')}
              >
                <div className="sidebar-category-title">
                  <FaBell className="sidebar-icon" />
                  <span className="sidebar-text">
                    {translate('notifications')}
                  </span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['notifications'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['notifications']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/notification/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaBell className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('notificationsList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/notification/send"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaBell className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('sendNotifications')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Services Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('services')}
              >
                <div className="sidebar-category-title">
                  <FaPhone className="sidebar-icon" />
                  <span className="sidebar-text">{translate('services')}</span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['services'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['services']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/services/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaPhone className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('servicesList')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/services/add"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaPhone className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('addService')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Settings Category */}
            <div className="sidebar-category">
              <button
                className="sidebar-category-header"
                onClick={() => toggleCategory('settings')}
              >
                <div className="sidebar-category-title">
                  <FaCog className="sidebar-icon" />
                  <span className="sidebar-text">{translate('settings')}</span>
                </div>
                <FaChevronRight
                  className={`sidebar-chevron ${
                    openCategories['settings'] ? 'open' : ''
                  }`}
                />
              </button>
              <Collapse isOpen={openCategories['settings']}>
                <Nav vertical>
                  <NavItem>
                    <NavLink
                      to="/serviceCategory/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('serviceCategories')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/governorate/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('governorates')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/area/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">{translate('areas')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/PropertyType/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('propertyTypes')}
                      </span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      to="/filterKey/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('filterKeys')}
                      </span>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      to="/addon/list"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaMapMarkerAlt className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('addons')}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/settings"
                      className="nav-link"
                      onClick={closeSidebar}
                    >
                      <FaCog className="sidebar-icon" />
                      <span className="sidebar-text">
                        {translate('settings')}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>

            {/* Logout */}
            <NavItem className="sidebar-logout">
              <NavLink
                to="/"
                className="nav-link"
                onClick={() => {
                  dispatch(logout());
                  closeSidebar();
                }}
              >
                <FaSignOutAlt className="sidebar-icon" />
                <span className="sidebar-text">{translate('logout')}</span>
              </NavLink>
            </NavItem>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
