import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import ScreenWrapper from './ScreenWrapper';

import DashboardData from '../dashboard/MobileApp';
import RequestsToAddProperty from '../PropertyRequests/RequestsToAddProperty/index';
import VisitingRequests from '../PropertyRequests/VisitingRequests/index';
import RejectedRequests from '../PropertyRequests/RejectedRequests/index';
import Properties from '../Properties/ActiveProperties/index';
import Property from '../Properties/Property/index';
import SoldProperties from '../Properties/SoldProperties/index';
import ExpiredProperties from '../Properties/ExpiredProperties/index';
import RentedProperties from '../Properties/RentedProperties/index';
import UsersListTable from '../Users/ListTable/index';
import UserCompaniesListTable from '../Users/ListTableCompany/index';
import UsersAddForm from '../Users/AddForm/index';
import UsersEditForm from '../Users/EditForm/index';
import UsersViewForm from '../Users/ViewForm/index';
import AdminsListTable from '../Admins/ListTable/index';
import AdminsAddForm from '../Admins/AddForm/index';
import AdminsEditForm from '../Admins/EditForm/index';
import AdminsViewForm from '../Admins/ViewForm/index';
import AgentsListTable from '../Agents/ListTable/index';
import AgentsAddForm from '../Agents/AddForm/index';
import AgentsEditForm from '../Agents/EditForm/index';
import AgentsViewForm from '../Agents/ViewForm/index';
import NotificationData from '../Notification/AddForm';
import NotificationList from '../Notification/ListTable/index';
import Services from '../Services/ListTable';
import AddService from '../Services/AddForm/index';
import ViewService from '../Services/ViewForm/index';
import EditService from '../Services/EditForm/index';
import AreaListTable from '../Settings/Area/ListTable/index';
import AreaAddForm from '../Settings/Area/AddForm/index';
import AreaEditForm from '../Settings/Area/EditForm/index';
import AreaViewForm from '../Settings/Area/ViewForm/index';
import GovernorateListTable from '../Settings/Governorate/ListTable/index';
import GovernorateAddForm from '../Settings/Governorate/AddForm/index';
import GovernorateEditForm from '../Settings/Governorate/EditForm/index';
import GovernorateViewForm from '../Settings/Governorate/ViewForm/index';
import AddonListTable from '../Settings/Addon/ListTable/index';
import AddonAddForm from '../Settings/Addon/AddForm/index';
import AddonEditForm from '../Settings/Addon/EditForm/index';
import AddonViewForm from '../Settings/Addon/ViewForm/index';
import FilterKeyListTable from '../Settings/FilterKey/ListTable/index';
import FilterKeyAddForm from '../Settings/FilterKey/AddForm/index';
import FilterKeyEditForm from '../Settings/FilterKey/EditForm/index';
import FilterKeyViewForm from '../Settings/FilterKey/ViewForm/index';
import PropertyTypeListTable from '../Settings/PropertyType/ListTable/index';
import PropertyTypeAddForm from '../Settings/PropertyType/AddForm/index';
import PropertyTypeEditForm from '../Settings/PropertyType/EditForm/index';
import PropertyTypeViewForm from '../Settings/PropertyType/ViewForm/index';
import ServiceCategoryListTable from '../Settings/ServiceCategory/ListTable/index';
import ServiceCategoryAddForm from '../Settings/ServiceCategory/AddForm/index';
import ServiceCategoryEditForm from '../Settings/ServiceCategory/EditForm/index';
import ServiceCategoryViewForm from '../Settings/ServiceCategory/ViewForm/index';
import EstateMarketListTable from '../EstateMarket/ListTable/index';
import EstateMarketAddForm from '../EstateMarket/AddForm/index';
import EstateMarketEditForm from '../EstateMarket/EditForm/index';
import EstateMarketViewForm from '../EstateMarket/ViewForm/index';
import EstateRequestFindListTable from '../EstateRequestsFind/ListTable/index';
import Settings from '../Settings/Settings/index';
import InvoiceListTable from '../Invoices/ListTable/index';

import { useLanguage } from '../../i18n/LanguageContext';
import axios from 'axios';
import {
  setLoggedUserData,
  setLookupsData,
} from '../../redux/actions/authAction';
import { showToast } from '../../utils/toast';
import { LookupsAPI } from '../../apis';
import useErrorHandling from '../../utils/error';

const Router = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandling();
  const { language, translate } = useLanguage();
  const [firstLaunch, setFirstLaunch] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const getLookups = async () => {
    try {
      const response = await LookupsAPI.getLookups('all');

      if (response.status === 200) {
        dispatch(setLookupsData(response.data.data));
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    async function checkLogin() {
      try {
        const loggedData = await localStorage.getItem('loggedData');
        if (loggedData) {
          const parsedLoggedData = JSON.parse(loggedData);
          axios.defaults.headers.common['Accept-Language'] = language;
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${parsedLoggedData?.token}`;
          dispatch(setLoggedUserData(parsedLoggedData));
          await getLookups();
          setIsLoaded(true);
        } else {
          setIsLoaded(true);
        }
      } catch (error) {
        setIsLoaded(false);
        console.error(error);
      }
    }
    if (firstLaunch && !isLoggedIn) {
      checkLogin();
      setFirstLaunch(false);
    } else if (firstLaunch && isLoggedIn) {
      setFirstLaunch(false);
      setIsLoaded(true);
    }
  }, [firstLaunch, isLoggedIn]);

  return (
    <MainWrapper>
      {isLoaded && !firstLaunch && (
        <main>
          <Routes>
            <Route path="/login" element={<LogIn />} />
            <Route
              path="/"
              element={isLoggedIn ? <Navigate to="/dashboard" /> : <LogIn />}
            />
            <Route
              element={
                isLoggedIn ? <ScreenWrapper /> : <Navigate to="/login" />
              }
            >
              <Route path="/dashboard" element={<DashboardData />} />
              <Route
                path="/requestsAddProperty"
                element={<RequestsToAddProperty />}
              />
              <Route path="/visitingRequests" element={<VisitingRequests />} />
              <Route path="/rejectedRequests" element={<RejectedRequests />} />
              <Route path="/activeProperties" element={<Properties />} />
              <Route
                path="/property/:actionId/:status/:propertyId"
                element={<Property />}
              />
              <Route path="/soldProperties" element={<SoldProperties />} />
              <Route
                path="/expiredProperties"
                element={<ExpiredProperties />}
              />
              <Route path="/rentedProperties" element={<RentedProperties />} />

              <Route path="/users/list" element={<UsersListTable />} />
              <Route
                path="/users/companyList"
                element={<UserCompaniesListTable />}
              />
              <Route path="/users/add" element={<UsersAddForm />} />
              <Route path="/users/edit" element={<UsersEditForm />} />
              <Route path="/users/view" element={<UsersViewForm />} />

              <Route path="/admins/list" element={<AdminsListTable />} />
              <Route path="/admins/add" element={<AdminsAddForm />} />
              <Route path="/admins/edit" element={<AdminsEditForm />} />
              <Route path="/admins/view" element={<AdminsViewForm />} />

              <Route path="/agents/list" element={<AgentsListTable />} />
              <Route path="/agents/add" element={<AgentsAddForm />} />
              <Route path="/agents/edit" element={<AgentsEditForm />} />
              <Route path="/agents/view" element={<AgentsViewForm />} />

              <Route path="/notification/send" element={<NotificationData />} />
              <Route path="/notification/list" element={<NotificationList />} />

              <Route path="/services/list" element={<Services />} />
              <Route path="/services/add" element={<AddService />} />
              <Route path="/services/edit/:id" element={<EditService />} />
              <Route path="/viewService/:id" element={<ViewService />} />

              <Route path="/area/list" element={<AreaListTable />} />
              <Route path="/area/add" element={<AreaAddForm />} />
              <Route path="/area/edit" element={<AreaEditForm />} />
              <Route path="/area/view" element={<AreaViewForm />} />

              <Route path="/invoice/list" element={<InvoiceListTable />} />

              <Route
                path="/governorate/list"
                element={<GovernorateListTable />}
              />
              <Route path="/governorate/add" element={<GovernorateAddForm />} />
              <Route
                path="/governorate/edit"
                element={<GovernorateEditForm />}
              />
              <Route
                path="/governorate/view"
                element={<GovernorateViewForm />}
              />

              <Route path="/filterKey/list" element={<FilterKeyListTable />} />
              <Route path="/filterKey/add" element={<FilterKeyAddForm />} />
              <Route path="/filterKey/edit" element={<FilterKeyEditForm />} />
              <Route path="/filterKey/view" element={<FilterKeyViewForm />} />

              <Route
                path="/PropertyType/list"
                element={<PropertyTypeListTable />}
              />
              <Route
                path="/PropertyType/add"
                element={<PropertyTypeAddForm />}
              />
              <Route
                path="/PropertyType/edit"
                element={<PropertyTypeEditForm />}
              />
              <Route
                path="/PropertyType/view"
                element={<PropertyTypeViewForm />}
              />

              <Route path="/addon/list" element={<AddonListTable />} />
              <Route path="/addon/add" element={<AddonAddForm />} />
              <Route path="/addon/edit" element={<AddonEditForm />} />
              <Route path="/addon/view" element={<AddonViewForm />} />

              <Route
                path="/serviceCategory/list"
                element={<ServiceCategoryListTable />}
              />
              <Route
                path="/serviceCategory/add"
                element={<ServiceCategoryAddForm />}
              />
              <Route
                path="/serviceCategory/edit"
                element={<ServiceCategoryEditForm />}
              />
              <Route
                path="/serviceCategory/view"
                element={<ServiceCategoryViewForm />}
              />

              <Route
                path="/estateMarket/list"
                element={<EstateMarketListTable />}
              />
              <Route
                path="/estateMarket/add"
                element={<EstateMarketAddForm />}
              />
              <Route
                path="/estateMarket/edit"
                element={<EstateMarketEditForm />}
              />
              <Route
                path="/estateMarket/view"
                element={<EstateMarketViewForm />}
              />

              <Route
                path="/EstateRequestFind/list"
                element={<EstateRequestFindListTable />}
              />

              <Route path="/settings" element={<Settings />} />
            </Route>
          </Routes>
        </main>
      )}
    </MainWrapper>
  );
};

export default Router;
