import React, { useState } from 'react';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Button, Col, Card, CardBody } from 'reactstrap';

import {
  REQUEST_TYPE,
  REQUEST_STATUS,
} from '../../../../utils/allDropdownData';
import * as Config from '../../../../config';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { showToast } from '../../../../utils/toast';
import useErrorHandling from '../../../../utils/error';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const RequestItem = ({ request, onSellRent, onDelete }) => {
  const navigate = useNavigate();
  const handleError = useErrorHandling();

  const { translate, language } = useLanguage();

  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    rentedOrSoldPrice: 0,
    rentedOrSoldPriceMS: 0,
    rentedOrSoldDate: formatDate(new Date()),
  });

  const handleRent = async () => {
    const dataToSend = {
      ...request,
      requestStatusId: REQUEST_STATUS.RENTED,
      rentedOrSoldPrice: formData.rentedOrSoldPrice,
      rentedOrSoldPriceMS: formData.rentedOrSoldPriceMS,
      rentedOrSoldDate: formData.rentedOrSoldDate,
    };

    axios
      .put(`${Config.BASE_URL}Admin-Estate/updateEstate`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          onSellRent();
          showToast(translate('updatedSuccessfully'), 'success');
          setShowPopup(false);
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleSell = async () => {
    const dataToSend = {
      ...request,
      requestStatusId: REQUEST_STATUS.SOLD,
      rentedOrSoldPrice: formData.rentedOrSoldPrice,
      rentedOrSoldPriceMS: formData.rentedOrSoldPriceMS,
      rentedOrSoldDate: formData.rentedOrSoldDate,
    };

    axios
      .put(`${Config.BASE_URL}Admin-Estate/updateEstate`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          onSellRent();
          showToast(translate('updatedSuccessfully'), 'success');
          setShowPopup(false);
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const locationValue = request?.locationByUser || '-';

  const LinkifyText = ({ text }) => {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(linkRegex);

    return (
      <span>
        {parts.map((part, index) => {
          if (linkRegex.test(part)) {
            return (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                {part}
              </a>
            );
          }
          return <span key={index}>{part}</span>;
        })}
      </span>
    );
  };

  return (
    <>
      <div className="property-requests-table">
        <Link to={`/property/3/${request?.requestStatusId}/${request._id}`}>
          <div className="d-flex inner-container">
            <img
              src={
                request?.mainImage
                  ? `${Config.UPLOADS_URL}uploads/${request?.mainImage}`
                  : require('../../../../assets/images/icons/avatar.jpg')
              }
              alt="profile"
              style={{
                borderRadius: '10%',
                margin: '10px',
                width: '70px',
                height: '70px',
                marginLeft: '20px',
              }}
            />

            <Table responsive style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr style={styles.center}>
                  <p style={{ fontWeight: 'bold', margin: 0 }}>
                    {language === 'en'
                      ? request?.userDetails?.nameEn
                      : request?.userDetails?.nameAr}
                  </p>
                  <th style={styles.center}>{translate('governorate')}</th>
                  <th style={styles.center}>{translate('area')}</th>
                  <th style={{ width: '25%', minWidth: '200px' }}>
                    {translate('address')}
                  </th>
                  <th style={styles.center}>{translate('propertyType')}</th>
                  <th style={styles.center}>{translate('referenceNo')}</th>
                  <th style={styles.center}>{translate('agent')}</th>

                  {/* <th>{translate('block')}</th>
                  <th>{translate('streetTitle')}</th>
                  <th>{translate('homeTitle')}</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={styles.center}>
                    <p>{request?.userDetails?.phoneNumber}</p>
                  </td>
                  <td style={styles.center}>
                    {request?.governorateDetails?.title?.[language]}
                  </td>
                  <td style={styles.center}>
                    {request?.areaDetails?.title?.[language]}
                  </td>
                  <td
                    style={{
                      width: '25%',
                      minWidth: '200px',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word',
                      textAlign: 'center',
                    }}
                  >
                    <LinkifyText text={locationValue} />
                  </td>
                  <td style={styles.center}>
                    {request?.propertyTypeDetails?.title?.[language]}
                  </td>

                  {/* <td>
                    {request?.block && request?.block <= 0
                      ? ''
                      : request?.block}
                  </td>
                  <td>{request?.streetNo ? request?.streetNo : '-'}</td>
                  <td>{request?.homeNo ? request?.homeNo : '-'}</td> */}
                  <td style={styles.center}>{request?.referenceNo}</td>
                  <td style={styles.center}>
                    {request?.agentDetails
                      ? language === 'en'
                        ? request?.agentDetails?.nameEn
                        : request?.agentDetails?.nameAr
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Link>
        <div className="actions">
          <Button
            onClick={() => {
              navigate(
                `/property/2/${request?.requestStatusId}/${request?._id}`
              );
            }}
            color="primary"
            style={{ padding: '5px 10px', background: 'green' }}
          >
            {translate('edit')}
          </Button>
          {request?.requestTypeId === REQUEST_TYPE.RENT && (
            <Button
              onClick={() => setShowPopup(true)}
              color="success"
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                width: '70px',
              }}
            >
              {translate('rented')}
            </Button>
          )}
          {request?.requestTypeId === REQUEST_TYPE.SELL && (
            <Button
              onClick={() => setShowPopup(true)}
              color="success"
              style={{
                marginRight: '10px',
                padding: '5px 10px',
                width: '70px',
              }}
            >
              {translate('soldOut')}
            </Button>
          )}
          <Button
            onClick={() => onDelete(request?._id)}
            color="danger"
            style={{ marginRight: '10px', padding: '5px 10px' }}
          >
            {translate('delete')}
          </Button>
        </div>
      </div>
      <div
        className="line"
        style={{
          width: '97%',
          border: '1px solid rgba(96, 96, 96, 0.1)',
          margin: '10px auto',
        }}
      />
      {showPopup && (
        <SweetAlert
          show={showPopup}
          title={
            request?.requestTypeId === REQUEST_TYPE.SELL
              ? translate('sellInfo')
              : translate('rentInfo')
          }
          onConfirm={
            request?.requestTypeId === REQUEST_TYPE.SELL
              ? handleSell
              : handleRent
          }
          onCancel={() => setShowPopup(false)}
          showCancel
          confirmBtnText={translate('submit')}
          cancelBtnText={translate('cancel')}
        >
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <form className="form form--horizontal">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('price')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={formData.location}
                        name={'price'}
                        component="input"
                        type="text"
                        placeholder={translate('enterPrice')}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            rentedOrSoldPrice: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('pricePerMeterSquare')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={formData.location}
                        name={'price'}
                        component="input"
                        type="text"
                        placeholder={translate('enterPrice')}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            rentedOrSoldPriceMS: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('todayDate')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={formData.rentedOrSoldDate}
                        name={'date'}
                        component="input"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </SweetAlert>
      )}
    </>
  );
};
const styles = {
  center: {
    textAlign: 'center',
  },
};
export default RequestItem;
