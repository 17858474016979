import axios from 'axios';
import { BASE_URL } from './config';

export const listInvoices = async (
  searchField = '',
  page = '',
  perPage = ''
) => {
  const searchParam = searchField ? `&search=${searchField}` : '';
  const pageParam = page ? `?page=${page}` : '';
  const perPageParam = perPage ? `&perPage=${perPage}` : '';
  const url = `${BASE_URL}Admin-Invoice/listInvoices${
    pageParam && perPageParam ? pageParam + perPageParam : ''
  }${searchParam}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};
